﻿$cssversion: 4;

@use "sass:math";
@use "sass:color";

// colours
$colorBody: #1b1b1b !default;
$colorBase: #666 !default;
$colorBaseDark: #333 !default;

$colorFocus: #000 !default;
$colorFocusOnDark: #fff !default;
$colorFocusDarkOnLight: #919191 !default;

$colorBlack: #000 !default;
$colorBlackLight: color.adjust($colorBlack, $lightness: 14%) !default;
$colorWhite: #fff !default;
$colorOffWhite: #f3f3f3 !default;

$colorError: #dd4157 !default;
$colorErrorDark: #bb3649 !default;

$colorSuccess: #79b928 !default;
$colorSuccessDark: #65a01b !default;

$colorWarning: #ffcc00 !default;
$colorWarningDark: #e5b700 !default;

$colorNotice: #ececec !default;
$colorNoticeDark: #c9c9c9 !default;

$colorGreen: #70ae21 !default;
$colorGreenAlt1: #5B8B1E !default;
$colorGreenAlt2: #79B928 !default;
$colorGreenAlt3: #AFD57E !default;

$colorBlue: #0066cc !default;

// site specific
$colorBackground: #000 !default;
$colorPrimary: #79b928 !default;
$colorPrimaryDark: #65a01b !default;
$colorSecondary: #ececec !default;
$colorSecondaryDark: #c9c9c9 !default;
$colorNavDivider: #242424 !default;

// 50 shades
$colorGrey: #999 !default;
$colorGreyDark: #666 !default;
$colorGreyLight: #ccc !default;
$colorGreyLighter: #fbfbfb !default;
$colorGreyAlt: #ebebeb !default;
$colorBorder: #aeaeae !default;
$colorBorderAlt: #dcdcdc !default;
$colorDivider: #ececec !default;
$colorGreyAlt2: #565656 !default;
$colorGreyAlt3: #F9F9F9 !default;

// links
$colorLink: $colorBase !default;
$colorLinkHover: $colorGrey !default;

// social colours
$colorSocialFacebook: #3b5998 !default;
$colorSocialTwitter: #55acee !default;
$colorSocialGooglePlus: #dd4b39 !default;
$colorSocialLinkedIn: #007bb5 !default;
$colorSocialYouTube: #dd0000 !default;
$colorSocialInstagram: #517fa4 !default;
$colorSocialPinterest: #cb2027 !default;
$colorSocialVimeo: #aad450 !default;
$colorSocialFlickr: #ff0084 !default;
$colorSocialRss: #ff6600 !default;

// fonts
$fontBase: "DysonFutura", Helvetica, Arial, sans-serif !default;

// responsive breakpoints
$maxPageWidth: 1280px !default;
$maxContentWidth: 834px !default;
$maxFormWidth: 725px !default;

$bpMobileLarge: 576px !default;
$bpTablet: 768px !default;
$bpTabletLarge: 980px !default;
$bpDesktop: 1025px !default;
$bpDesktopLarge: 1200px !default;

$bpNavSearch: $bpDesktop + 100 !default;

// Header
$heightOfJobsToast: 68px !default;
$headerHeightMobile: 56px !default;
$headerHeightTablet: 60px !default;
$headerHeightDesktop: 64px !default;

// Timeline
$timelineCardHeight: 225px !default;
$timelineCardWidthMobile: 320px !default;
$timelineCardWidthDesktop: 400px !default;
$timelineVerticalSpacing: 60px !default;
$timelineBorderWidth: 3px !default;
$timelineHeight: $timelineCardHeight + ($timelineVerticalSpacing *3) + 95 !default;
$timelineBackgroundColor: #fbfbfb !default; // #CCC when we have iscroll

$timelineCardHeightLargeText: 325px !default;
$timelineHeightLargeText: $timelineCardHeightLargeText + ($timelineVerticalSpacing *3) + 95 !default;

$timelineCardHeightExtraLargeText: 425px !default;
$timelineHeightExtraLargeText: $timelineCardHeightExtraLargeText + ($timelineVerticalSpacing *3) + 95 !default;

// tabs

$tabsBorderHightlightColor: #78b92b !default;
$tabsInactiveBackgroundColor: #fbfbfb !default;

// media queries

$mq: false !default;

// critical / deferred

$critical: true !default;
$deferred: true !default;
