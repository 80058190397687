@use "sass:math";
@use "sass:color";
@use "config" as *;

@function calculateRem($a) {
  $b: math.div($a, 16px);
  @return $b * 1rem;
}

@mixin font-size($size: 16px) {
  font-size: calculateRem(($size - 1));

  .all-fonts-loaded & {
    font-size: calculateRem($size);
  }
}

@mixin line-height($size: 24px) {
  line-height: calculateRem($size);
}

@mixin bg($url, $repeat: no-repeat, $position: 0 0) {
  background-image: url(#{$url}?v=#{$cssversion});
  background-repeat: $repeat;
  background-position: $position;
}

@mixin min-height($a: 5px) {
  min-height: $a;
  height: auto !important;
  height: $a;
}

@mixin filter($a) {
  -moz-filter: $a;
  -ms-filter: $a;
  -webkit-filter: $a;
  filter: $a;
}

@mixin gradient-vertical($a: #eee, $b: #fff) {
  background: linear-gradient(to bottom, $a, $b);
}

@mixin gradient-horizontal($a: #eee, $b: #fff) {
  background: linear-gradient(to right, $a, $b);
}

@mixin transition($a...) {
  -webkit-transition: $a;
  transition: $a;
}

@mixin transform-origin($a) {
  -ms-transform-origin: $a;
  -webkit-transform-origin: $a;
  transform-origin: $a;
}

@mixin animation($a) {
  -webkit-animation: $a;
  animation: $a;
}

@mixin animation-pause() {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

@mixin background-size($a: 100% auto) {
  background-size: $a;
}

@mixin background-clip($a: padding-box) {
  background-clip: $a;
}

@mixin box-shadow($a: 1px 1px 2px 0 rgba(0,0,0,.08)) {
  box-shadow: $a;
}

@mixin box-sizing($a: border-box) {
  box-sizing: $a;
}

@mixin border-radius($a: 2px) {
  border-radius: $a;
}

@mixin border-radius-adv($tl: 2px, $tr: 2px, $bl: 2px, $br: 2px) {
  border-top-right-radius: $tr;
  border-bottom-right-radius: $br;
  border-bottom-left-radius: $bl;
  border-top-left-radius: $tl;
  @include background-clip();
}

@mixin text-shadow($a: 1px 1px 1px rgba(0,0,0,.2)) {
  text-shadow: $a;
}

@mixin text-overflow($a: ellipsis) {
  overflow: hidden;
  -ms-text-overflow: $a;
  text-overflow: $a;
  white-space: nowrap;
}

@mixin text-hidden() {
  overflow: hidden;
  text-indent: -9999px;
}

@mixin remove-text-hidden() {
  overflow: visible;
  text-indent: 0;
}

@mixin text-size-adjust($a: 100%) {
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

@mixin placeholder-text($a: $colorGrey) {
  &:-moz-placeholder {
    color: $a;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $a;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $a;
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: $a;
    opacity: 1;
  }

  &::placeholder {
    color: $a;
    opacity: 1;
  }
}

@mixin transform($a) {
  transform: $a;
}

@mixin rotate($a: -5deg) {
  @include transform(rotate($a));
}

@mixin user-select($a: none) {
  -ms-user-select: $a;
  -webkit-user-select: $a;
  user-select: $a;
}

@mixin object-fit($a: cover) {
  object-fit: $a;
}

@mixin ratio($width, $height, $value: 'padding-top') {
  #{$value}: (math.div($height, $width) * 100) * 1%;
}

@mixin hide() {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin background-fill() {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NUNFMjE4RjNFQkREMTFFNjlDMzlCMDNCOUY5MkE3QTAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NUNFMjE4RjRFQkREMTFFNjlDMzlCMDNCOUY5MkE3QTAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1Q0UyMThGMUVCREQxMUU2OUMzOUIwM0I5RjkyQTdBMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1Q0UyMThGMkVCREQxMUU2OUMzOUIwM0I5RjkyQTdBMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Prx7KIgAAAAQSURBVHjaYvj//z8DQIABAAj8Av7bok0WAAAAAElFTkSuQmCC') repeat 0 0;
}

@mixin critical() {
  @if $critical {
    @content;
  }
}

@mixin deferred() {
  @if $deferred {
    @content;
  }
}

@mixin mq-min($a) {
  @if $mq {
    @if $mq >= $a {
      @content;
    }
  }
  @else {
    @media screen and (min-width: $a) {
      @content;
    }
  }
}

@mixin mq-max($a) {
  @if $mq {
    @if $mq <= $a {
      @content;
    }
  }
  @else {

    @media screen and (max-width: $a) {
      @content;
    }
  }
}

@mixin focus-hide() {
  .js-focus-visible &:focus:not(.focus-visible) {
    @content;
  }
}

@mixin appearance-none() {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
