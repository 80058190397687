﻿@use "../config" as *;
@use "../mixins" as *;
$critical: true;
$deferred: true;

.m-global-map
{
	@include critical
	{
		background: $colorGreyAlt;
		padding: 22px 0;
		@include mq-min($bpTablet)
		{
			padding: 22px 0;
		}
		@include mq-min($bpDesktop)
		{
			padding: 35px 0;
		}
	}
	&__header
	{
		@include critical
		{
			text-align: center;
			max-width: 800px;
			margin: 0 auto;
			padding: 0 0 10px 0;
		}
	}
	&__description
	{
		@include critical
		{
			.js &:not(.m-global-map__description--mobile)
			{
				@include mq-max($bpTablet - 1)
				{
					display: none;
				}
			}
			&--mobile
			{
				padding: 30px 0 0 0;
				@include mq-min($bpTablet)
				{
					display: none;
				}
			}
		}
	}
	&__container
	{
		@include critical
		{
			position: relative;
		}
	}
	&__container__inner
	{
		@include critical
		{
			@include ratio(1160, 692.6);
			position: relative;

			svg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
			}
		}
	}
	&__area
	{
		@include critical
		{
			@include transition(fill 0.5s ease);

			path
			{
				stroke: $colorWhite;
				stroke-width: .25;
				stroke-linecap: round;
				stroke-linejoin: round;
				stroke-miterlimit: 10;
				fill-rule: evenodd;
				clip-rule: evenodd;
				fill: $colorWhite;
			}

			&--active
			{

				path
				{
					fill: $colorGreen;
					stroke: $colorGreen;
				}
			}
			&--europe
			{
				path
				{
					@include mq-max($bpTablet - 1)
					{
						stroke: $colorGreenAlt1;
						fill: $colorGreenAlt1;
					}
				}
			}
			&--americas
			{
				path
				{
					@include mq-max($bpTablet - 1)
					{
						stroke: $colorGreenAlt2;
						fill: $colorGreenAlt2;
					}
				}
			}
			&--asia
			{
				path
				{
					@include mq-max($bpTablet - 1)
					{
						stroke: $colorGreenAlt3;
						fill: $colorGreenAlt3;
					}
				}
			}
		}
	}
	&__pins
	{
		@include critical
		{
			margin: 0;
			padding: 15px 0 0 0;
			list-style: none;
			@include mq-min($bpTablet)
			{
				padding: 0;
			}
		}
	}
	&__pin
	{
		@include critical
		{
			@include mq-max($bpTablet - 1)
			{
				&:not(:first-child)
				{
					padding-top: 20px;
				}
			}
			@include mq-min($bpTablet)
			{
				width: 4%; // @todo - adjust
				position: absolute;
				top: -9999px;
				left: -9999px;
				@include transform(translate(-50%, -50%));
				will-change: transform;
				cursor: pointer;
				&--europe
				{
					top: 38%;
					left: 52%;
				}
				&--americas
				{
					top: 42%;
					left: 17%;
				}
				&--asia
				{
					top: 47%;
					left: 75%;
				}
				.js-tool-tip
				{
					top: 0; // -38px
					left: 50%;
					width: auto;
					padding-bottom: 38px;
					@include mq-min($bpDesktop)
					{
						width: auto;
					}
				}
			}
		}
	}
	&__pin__link
	{
		@include critical
		{
			&:not(:last-child)
			{
				margin-right: 0;
			}
			@include mq-min($bpTablet)
			{
				padding: 0;
				margin: 0;
				@include ratio(1,1);
				display: block;
				position: relative;
				min-width: 100%;
				max-width: 100%;
				background: none;
				overflow: visible;
				color: $colorBase;
				&:focus, &:hover, &:active
				{
					background: none;
					color: $colorBase;
					&:before
					{
						width: 100%;
						height: 100%;
						left: 0;
						.no-touchevents &
						{
							opacity: 1;
						}
					}
					&:after
					{
						.no-touchevents &
						{
							opacity: 0;
						}
					}
				}
				&:active
				{
					animation: none;
				}
				&:before
				{
					content: '';
					display: block;
					background: none;
					background-image: url(/images/global-map-pin.webp);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-position: 0 0;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 1;
					@include transform(none);
					@include transition(opacity 0.5s ease);
				}
				&:after
				{
					content: '';
					display: block;
					background: none;
					background-image: url(/images/global-map-pin-hover.webp);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-position: 0 0;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
					@include transform(none);
					@include transition(opacity 0.5s ease);
				}
				&--hover
				{
					&:before
					{
						opacity: 0 !important;
					}
					&:after
					{
						opacity: 1 !important;
					}
				}
			}
		}
	}
	&__pin__link__prefix
	{
		@include critical
		{
			@include mq-max($bpTablet - 1)
			{
				@include hide();
			}
		}
	}
	&__pin__link__inner
	{
		@include critical
		{
			@include mq-min($bpTablet)
			{
				@include hide();
			}
		}
	}
	&__info
	{
		@include critical
		{
			.js &
			{
				display: none;
			}
		}
	}
	&__info__item
	{
		@include deferred
		{
			.js &
			{
				background: $colorWhite;
				max-width: 560px;
				width: 90%;
				margin: 0 auto;
				position: relative; // for popup close button positioning
			}
		}
	}
	&__info__item__content
	{
		@include deferred
		{
			.js &
			{
				padding: 15px 15px 0 15px;
				@include mq-min($bpTablet)
				{
					padding: 20px 20px 5px 20px;
				}
				@include mq-min($bpDesktop)
				{
					padding: 24px 24px 9px 24px;
				}
			}
		}
	}
	&__info__item__title
	{
		@include deferred
		{
			.js &
			{
				padding-right: 100px;
			}
		}
	}
	&__info__item__actions
	{
		@include deferred
		{
			.js &
			{
				padding: 20px 15px;
				margin: 0;
				list-style: none;
				border-top: 1px solid $colorBorderAlt;
				text-align: center;

				@include mq-min($bpTablet)
				{
					padding: 20px 10px;
				}

				@include mq-min($bpDesktop)
				{
					padding: 24px 12px;
				}
			}
		}
	}
	&__info__item__actions--1
	{
		@include deferred
		{
			.js &
			{
				.m-global-map__info__item__actions__item
				{
					@include mq-min($bpTablet)
					{
						display: inline-block;
						min-width: 240px;
					}
					@include mq-min($bpTablet)
					{
						min-width: 244px;
					}
					.form-button
					{
						@include deferred
						{
							&:focus
							{
								outline: 4px solid $colorFocusDarkOnLight;
								outline-offset: 1px;
							}
						}
					}
				}
			}
		}
	}
	&__info__item__actions--2
	{
		@include deferred
		{
			.js &
			{
				.m-global-map__info__item__actions__item
				{
					@include mq-min($bpTablet)
					{
						float: left;
						width: 50%;
					}
					.form-button
					{
						@include deferred
						{
							&:focus
							{
								outline: 4px solid $colorFocusDarkOnLight;
								outline-offset: 1px;
							}
						}
					}
				}
			}
		}
	}
	&__info__item__actions--3
	{
		@include deferred
		{
			.js &
			{
				.m-global-map__info__item__actions__item
				{
					@include mq-min($bpTablet)
					{
						float: left;
						width: 33.333333333%;
					}
					.form-button
					{
						@include deferred
						{
							&:focus
							{
								outline: 4px solid $colorFocusDarkOnLight;
								outline-offset: 1px;
							}
						}
					}
				}
			}
		}
	}
	&__info__item__actions--unlimited {
		@include deferred {
			.js & {
				.m-global-map__info__item__actions__item {
					&:not(:nth-child(-n+2)) {
						padding-top: 20px;
					}

					@include mq-min($bpTablet) {
						float: left;
						width: 50%;
					}
					.form-button
					{
						@include deferred
						{
							&:focus
							{
								outline: 4px solid $colorFocusDarkOnLight;
								outline-offset: 1px;
							}
						}
					}
				}
			}
		}
	}
	&__info__item__actions__item
	{
		@include deferred
		{
			.js &
			{
				&:not(:first-child)
				{
					padding-top: 20px;
					@include mq-min($bpTablet)
					{
						padding-top: 0;
					}
				}
				@include mq-min($bpTablet)
				{
					padding: 0 10px;
				}
				@include mq-min($bpDesktop)
				{
					padding: 0 12px;
				}
				.form-button
				{
					max-width: 100%;
					min-width: 100%;
				}
			}
		}
	}
}

.m-global-map + .m-block {

	@include critical
	{
		border-top: 0;
	}
}

.m-global-map__info__item {

	.js & {

		@include critical {
			display: none;
		}
	}

	.js .mfp-content & {

		@include critical {
			display: block;
		}
	}
}